import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import QrScanner from 'qr-scanner'; // Import the QR scanner library
import './Common.css'; // Import the CSS file

const FileUploadButton = ({ setScanOption, setScanner, videoRef }) => {
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const [scanner, setLocalScanner] = useState(null);
  const [showVideo, setShowVideo] = useState(false);
  const [cameraError, setCameraError] = useState(false);

  useEffect(() => {
    if (setScanOption === 'camera' && videoRef.current && showVideo) {
      const qrScanner = new QrScanner(
        videoRef.current,
        result => handleScan(result),
        {
          highlightScanRegion: true,
          returnDetailedScanResult: true,
          onDecodeError: () => {}, // Stop printing errors
        }
      );

      qrScanner.start()
        .then(() => setLocalScanner(qrScanner))
        .catch(error => {
          console.error('Camera not found or access denied:', error);
          setCameraError(true);
        });

      return () => {
        qrScanner.stop();
      };
    }
  }, [setScanOption, videoRef, showVideo]);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    try {
      const result = await QrScanner.scanImage(file, { returnDetailedScanResult: true });
      console.log('QR code content:', result.data);
      navigateToResult(result.data);
    } catch (error) {
      console.error('Error processing file:', error);
      navigateToResult(error.message, true);
    }
  };

  const handleScan = (result) => {
    handleCloseVideo();
    if (scanner) {
      scanner.stop();
    }
    navigateToResult(result.data);
  };

  const navigateToResult = (data, isError = false) => {
    navigate('/result', { 
      state: { 
        qrData: isError ? 'Error' : data,
        qrSubmissionId: isError ? 'Error' : 'Pending',
        isError: isError
      } 
    });
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const handleCameraClick = () => {
    setShowVideo(true);
    setScanOption('camera');
    setCameraError(false);  // Reset camera error state
    
    // Reset the scanner if it exists
    if (scanner) {
      scanner.stop();
      setLocalScanner(null);
    }
    
    // Stop any existing video tracks
    if (videoRef.current && videoRef.current.srcObject) {
      const tracks = videoRef.current.srcObject.getTracks();
      tracks.forEach(track => track.stop());
      videoRef.current.srcObject = null;
    }

    // Use setTimeout to ensure the video element is rendered
    setTimeout(() => {
      if (videoRef.current) {
        const qrScanner = new QrScanner(
          videoRef.current,
          result => handleScan(result),
          {
            highlightScanRegion: true,
            returnDetailedScanResult: true,
            onDecodeError: () => {}, // Stop printing errors
          }
        );

        qrScanner.start()
          .then(() => {
            setLocalScanner(qrScanner);
            // Scroll to the video box and center it
            videoRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
          })
          .catch(error => {
            console.error('Camera not found or access denied:', error);
            setCameraError(true);
          });
      } else {
        console.error('Video element not found');
        setCameraError(true);
      }
    }, 0);
  };

  const handleCloseVideo = () => {
    setShowVideo(false);
    if (scanner) {
      scanner.stop();
      setLocalScanner(null);
    }
    if (videoRef.current && videoRef.current.srcObject) {
      const tracks = videoRef.current.srcObject.getTracks();
      tracks.forEach(track => {
        track.stop();
        videoRef.current.srcObject.removeTrack(track);
      });
      videoRef.current.srcObject = null;
    }
    // Ensure the camera is turned off
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(stream => {
          stream.getTracks().forEach(track => track.stop());
        })
        .catch(err => console.error('Error accessing camera:', err));
    }
  };


  const setSessionData = (key, value) => {
    sessionStorage.setItem(key, value);
    setTimeout(() => {
      sessionStorage.removeItem(key);
    }, 60000); // Invalidate session after 1 minute
  };

  return (
    <div>
      <div className="icons">
        <div className="trade">
          <div className="fa-stack fa-3x">
            <i
              className="fas fa-file scan-icon"
              onClick={triggerFileInput}>
            </i>
          </div>
          <div className="icons-text">Upload QR Code</div>
        </div>
        <div className="trade">
          <div className="fa-stack fa-3x">
            <i
              className="fas fa-camera scan-icon"
              onClick={handleCameraClick}>
            </i>
          </div>
          <div className="icons-text">Take Picture</div>
        </div>
      </div>

      <input
        id="file-input"
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }} // Hide the file input element
        onChange={handleFileUpload}
      />

      {cameraError && (
        <p className="error-message">Camera not found. Please ensure your camera is connected and that you have granted permission for this site to access it.</p>
      )}

      {!cameraError && showVideo && (
        <div className="video-box">
          <button className="close-button" onClick={handleCloseVideo}>Close</button>
          <video ref={videoRef} className="qr-video"></video>
        </div>
      )}
    </div>
  );
};

export default FileUploadButton;

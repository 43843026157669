// src/pages/QRCodeScanner.js
import React, { useRef, useEffect, useState } from 'react';
import QrScanner from 'qr-scanner';
import FileUploadButton from '../components/FileUploadButton'; // Import the new component
import './Common.css'; // Import the CSS file
import Footer from '../utils/Footer'; // Import the Footer component
import { useNavigate } from 'react-router-dom';

const QRCodeScanner = () => {
  const videoRef = useRef(null);
  const [cameraError, setCameraError] = useState(false);
  const [scanOption, setScanOption] = useState(null); // To track user choice
  const [imageLoaded, setImageLoaded] = useState(false); // State to track if the image is loaded
  const navigate = useNavigate();

  useEffect(() => {
    if (scanOption === 'camera' && videoRef.current) {
      const qrScanner = new QrScanner(
        videoRef.current,
        result => handleScan(result),
        {
          highlightScanRegion: true,
          returnDetailedScanResult: true,
          onDecodeError: error => console.warn(error),
        }
      );

      qrScanner.start()
        .catch(error => {
          console.error('Camera not found or access denied:', error);
          setCameraError(true);
        });

      return () => {
        qrScanner.stop();
      };
    }
  }, [scanOption, videoRef]);

  useEffect(() => {
    const img = new Image();
    img.src = '/logo192.png';
    img.onload = () => setImageLoaded(true);
  }, []);

  const handleScan = (result) => {
    console.log('QR code found:', result.data);
    navigate('/result', { state: { qrData: result.data } });
  };

  return (
    <>
      <div className="logo-container">
        <img src="/logo.png" alt="Logo" className="logo" />
        <span className="logo-text">Is This QR</span>
        <span className="logo-text1">SAFE?</span>
      </div>
      <div className="test"></div>
      <div className="home-page">
        <h1>Analyze QR Codes</h1>
        <span className="fancy">Before You Click!</span>
        <p><i>Is-This-QR-Safe</i> is a free service that checks QR codes for potential malicious content.</p>
        <FileUploadButton
          setScanOption={setScanOption}
          videoRef={videoRef}
          cameraError={cameraError}
        />
        <div className="app-links">
          <div className="store-badges">
            <a href="https://apps.apple.com/us/app/is-this-qr-safe/id6737241777" target="_blank" rel="noopener noreferrer">
              <img src="/apple-store-badge.png" alt="Download on the App Store" className="store-badge" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.itqsmobileapp" target="_blank" rel="noopener noreferrer">
              <img src="/google-play-badge.png" alt="Get it on Google Play" className="store-badge" />
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default QRCodeScanner;
